<template>
  <div class="deviceMQBGView">
    <div class="deviceMQTab">
      <el-tabs :stretch="true" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane v-for="(item) in tabList"
                     :disabled="item.router==''"
                     :name="item.title"
                     :key="item.title"
        >
        <span slot="label">
          <h-stack>
            <div style="float: left;padding: 0px 5px 0px 5px">{{ item.title }}</div>
            <el-badge v-if="item.number>0" :value="item.number" size="mini" class="item"></el-badge>
          </h-stack>
        </span>
          <router-link :to="item.router"></router-link>
          <router-view
              :id="loadingDivId"
              v-if="item.show&&!loading"
              v-on:loadingChange="loadingChange"
              v-on:stopRefresh="stopRefresh"
              v-on:clickItem="clickItem"
              v-bind:show="item.show&&!loading"
              v-bind:search-info="fillterConfig"
              v-on:updateNumber="updateNumber"
          ></router-view>
        </el-tab-pane>
      </el-tabs>
    </div>
    <fillter-bar
        :fillter-info="fillterInfo"
        :loading="loadingData"
        @delayRefresh="delayRefresh"
        @refresh="refresh"
        @showFillter="showFillter"
        @startRefresh="startRefresh"
    >
    </fillter-bar>

    <el-drawer
        size="70%"
        class="fillterView"
        :with-header="false"
        :visible.sync="showFillterView"
        direction="rtl"
    >
      <fillter-view ref="fillterView" @updateSearch="updateSearch"></fillter-view>
    </el-drawer>

    <device-msg-box :dialog-visible="showDetailView"
                    :device-info="deviceInfo"
                    @deviceMsgBoxBGViewClose="deviceMsgBoxBGViewClose"
                    @fillterAction="fillterAction"
                    @deviceHistory="deviceHistory"
                    @stationHistory="stationHistory">
    </device-msg-box>
  </div>
</template>

<script>
import HStack from '@/components/hStack/hStack'
import Z2View from '@/views/deviceMQ/Z2View/Z2View'
import FillterBar from '@/views/deviceMQ/fillterBar/fillterBar'
import FillterView from '@/views/deviceMQ/fillterView/fillterView'
import DeviceMsgBox from '@/views/deviceMQ/deviceMsgBox/deviceMsgBox'
import deviceMQMixin from '@/views/deviceMQ/MQmixin/deviceMQMixin'

export default {
  name: 'deviceMQ',
  components: { DeviceMsgBox, FillterView, FillterBar, Z2View, HStack },
  mixins: [deviceMQMixin],
  data() {
    return {
      loadingDivId:'deviceMQ',
    }
  },
  methods: {
    stationHistory:function(res) {
      let query = {includeBaseStationMac: res.mac, name:this.activeName}
      this.$push('station-MQ', query)
    },
    deviceHistory: function(res) {
      let type = ''
      for (let i = 0; i < this.tabList.length; i++) {
        let info = this.tabList[i]
        if (!info.show) {
          continue
        }
        type = info.type
      }
      let query = { deviceType: type, deviceMac: res.mac }
      if (typeof this.fillterConfig['excludeBaseStationMac'] != 'undefined') {
        query['excludeBaseStationMac'] = this.fillterConfig['excludeBaseStationMac']
      }
      if (typeof this.fillterConfig['includeBaseStationMac'] != 'undefined') {
        query['includeBaseStationMac'] = this.fillterConfig['includeBaseStationMac']
      }
      query['name'] = this.activeName;
      this.$push('device-Station-MQ', query)
    },
    updateTabList: function(callBack) {
      let time = 10000
      for (let i = 0; i < this.fillterInfo.fillterList.length; i++) {
        let info = this.fillterInfo.fillterList[i]
        if (info.type == 'time') {
          time = info.value
          break
        }
      }
      this.$api.deviceMQ.getDeviceTotalCount({ time: time }).then(res => {
        let dataList = []
        if (res.code != 200) {
          callBack({ dataList: dataList })
          return
        }
        for (const key in res.data) {
          let title = ''
          let router = ''
          let order = 0
          let type = 1;
          if (key == 'z2s') {
            title = 'Z2'
            router = '/deviceMQ/Z2View'
            order = 1
            type = 1;
          } else if (key == 'z3s') {
            title = 'Z3'
            router = '/deviceMQ/Z3View'
            order = 2
            type = 2;
          } else if (key == 'z4s') {
            title = 'Z4'
            router = '/deviceMQ/Z4View'
            order = 3
            type = 3;
          } else if (key == 'z5s') {
            title = 'Z5'
            router = '/deviceMQ/Z5View'
            order = 4
            type = 4;
          } else if (key == 'braceletInfos') {
            title = '手环/胸卡'
            router = '/deviceMQ/BraceletView'
            order = 5
          } else if (key == 'incubatorInfos') {
            title = '温箱'
            router = '/deviceMQ/IncubatorView'
            order = 6
          } else if (key == 's2BraceletInfos') {
            title = 'S2'
            router = '/deviceMQ/S2View'
            order = 7
          } else {
            continue;
          }
          let number = 0
          if (typeof res.data[key] != 'undefined' && res.data[key] != null) {
            number = res.data[key]
          }
          let itemInfo = {
            title: title,
            number: number,
            router: router,
            show: false,
            order: order,
            type: type
          }
          dataList.push(itemInfo)
        }

        dataList.sort((a, b) => {
          return a.order - b.order
        })

        callBack({ dataList: dataList })
      }).catch(() => {
        let dataList = [
          { title: 'Z2', number: 0, router: '/deviceMQ/Z2View', show: false, type: 1 },
          { title: 'Z3', number: 0, router: '/deviceMQ/Z3View', show: false, type: 2 },
          { title: 'Z4', number: 0, router: '/deviceMQ/Z4View', show: false, type: 3 },
          { title: 'Z5', number: 0, router: '/deviceMQ/Z5View', show: false, type: 4 },
          { title: 'S2', number: 0, router: '/deviceMQ/S2View', show: false, type: 's2BraceletInfos' },
          { title: '温箱', number: 0, router: '/deviceMQ/IncubatorView', show: false, type: 'incubatorInfos' },
          { title: '手环/胸卡', number: 0, router: '/deviceMQ/BraceletView', show: false, type: 'braceletInfos' }
        ]
        callBack({ dataList: dataList })
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.deviceMQBGView {
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;

  .fillterView {
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .deviceMQTab {
    width: 100%;
    height: calc(100% - 100px);

    .el-tabs {
      height: 100%;

      .el-tabs__content {


        .el-tab-pane {
          height: 100%;
        }
      }
    }
  }

}


</style>
